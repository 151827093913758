<template>
  <div class="FAQ">
    <v-col align='center'>
      <div style="margin-top: 35px; margin-bottom: 35px">
        <span class="hello text-h1">คำถามที่พบบ่อย</span>
      </div>
    </v-col>

    <v-container fill-width fluid>
      <v-row align="center" justify="center" no-gutters>

        <v-col cols="12" sm="6" class="text-center">
          <div style="margin-top: 20px; margin-bottom: 50px; text-align: left;">
            <span class="text-h5 bring" style="font-weight: 300;"
            ><span class="text-h4"><span class="text-h3" style="color: #a26378">Q:</span> ที่มาของโรงเรียนมหิดลวิทยานุสรณ์</span><br>
              <span class="text-h3" style="color: #78c3c6">A:</span> เมื่อพ.ศ.2534
              มหาวิทยาลัยมหิดลร่วมกับกรมสามัญศึกษาจัดตั้งโรงเรียนระดับมัธยมศึกษาตอนปลาย
              เพื่อพัฒนานักเรียนให้เป็นกำลังสำคัญในการพัฒนาวิทยาศาสตร์และเทคโนโลยีของชาติ
              โรงเรียนมหิดลวิทยานุสรณ์จึงเป็นโรงเรียนวิทยาศาสตร์แห่งแรกของประเทศไทยที่มี
              สถานภาพเป็นองค์การมหาชน
              ภายใต้การกำกับดูแลของรัฐมนตรีว่าการกระทรวงศึกษาธิการ</span
            >
            <br /><br />
            <span class="text-h5 bring" style="font-weight: 300;"
            ><span class="text-h4"><span class="text-h3" style="color: #a26378">Q:</span> ต้องเสียค่าเทอมและค่ใช้จ่ายอะไรบ้าง</span><br>
              <span class="text-h3" style="color: #78c3c6">A:</span> ไม่เสียค่าเทอมเนื่องจากนักเรียนทุกคนได้รับทุนการศึกษาเต็มตลอดระยะเวลาที่เรียนอยู่ในโรงเรียน
              - ไม่ต้องเสียค่าที่พักโดยนักเรียนทุกคนจะต้องพักในหอพักนักเรียนภายในพื้นที่ของโรงเรียนเท่านั้น
              - นักเรียนจะได้รับเงินค่าอาหารคนละ 80 บาทต่อวันหรือ 2400 บาทต่อเดือนโอนเข้าบัญชีธนาคารของนักเรียนโดยตรง
              - เสียเฉพาะค่าใช้จ่ายส่วนตัวของนักเรียน เช่น ค่าชุดนักเรียน ชุดนอน ชุดพละ ชุดนศท. อุปกรณ์การเรียน เป็นต้น</span
            >
            <br /><br />
            <span class="text-h5 bring" style="font-weight: 300;"
            ><span class="text-h4"><span class="text-h3" style="color: #a26378">Q:</span> เรียนห้องละกี่คน และแต่ละปีมีการจัดห้องเรียนใหม่หรือไม่</span><br>
              <span class="text-h3" style="color: #78c3c6">A:</span> ทั้งระดับชั้นมีทั้งหมด 10 ห้อง ห้องละ 24 คน และอาจารย์ที่ปรึกษาห้องละ 2 ท่าน
              และไม่มีการจัดห้องใหม่ ทำให้นักเรียน จะอยู่กับอาจารย์ที่ปรึกษาทั้ง 2 ท่านตลอดระยะเวลา 3 ปี</span
            >
            <br /><br />
            <span class="text-h5 bring" style="font-weight: 300;"
            ><span class="text-h4"><span class="text-h3" style="color: #a26378">Q:</span> หอพักในโรงเรียนนอนห้องละกี่คน</span><br>
              <span class="text-h3" style="color: #78c3c6">A:</span> หอชายนอนห้องละ 4 คน หอหญิงนอนห้องละ 6 คน (บางห้องนอนห้องละ 4 คน)
              โดยในหอพักจะมีสิ่งอำนวยความสะดวกต่างๆ เช่น ตู้เย็น ไมโครเวฟ เครื่องปรับอากาศ เครื่องทำน้ำอุ่น เป็นต้น</span
            >
            <br /><br />
            <span class="text-h5 bring" style="font-weight: 300;" color=red
            ><span class="text-h4"><span class="text-h3" style="color: #a26378">Q:</span> นักเรียนโรงเรียนมหิดลวิทยานุสรณ์มีความเป็นอยู่อย่างไร</span><br>
              <span class="text-h3" style="color: #78c3c6">A:</span> นักเรียนโรงเรียนมหิดลวิทยานุสรณ์เป็นนักเรียนโรงเรียนประจำ
              โดยทุกคนต้องอยู่หอพักของโรงเรียนและกลับบ้านได้ในวันหยุดสุดสัปดาห์
              หรือถ้าเป็นนักเรียนต่างจังหวัด
              ก็สามารถพักอยู่ที่หอพักของโรงเรียนได้ตลอดช่วงเวลาเปิดภาคเรียน</span
            >
          </div>

          <div>
            <v-btn
              color='green'
              large
              outlined
              rounded
              x-large
              elevation="6"
              @click="$router.push({ name: '108'})"
            >
              <v-icon>{{ mdiHelpCircle }}</v-icon>
              <span class="text-h5 bring" style="font-weight:350" color='red'>
                คำถามเพิ่มเติม
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

  </div>

</template>

<script>
import { mdiHelpCircle } from '@mdi/js'

export default {
  data: () => ({
    mdiHelpCircle
  })
}
</script>

<style>
.FAQ {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  /* top: 200px; */
  /* bottom: 0; */
  left: 0;
  background: url('/assets/BG/bg2.webp');
  background-size: cover;
  /* background-repeat: repeat-y; */
  background-repeat: no-repeat;
  background-color: white;
}
</style>
