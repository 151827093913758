<template>
  <div class="LY">
    <v-col align='center'>
      <div style="margin-top: 35px; margin-bottom: 35px">
        <span class="hello text-h1">ภาพกิจกรรมปีที่ผ่านมา</span>
      </div>
    </v-col>
    <v-container fill-width fluid>
      <v-row align="center" justify="center" no-gutters>
        <v-col
          v-for="item in itemsss"
          :key="item.id"
          class="d-flex child-flex"
          cols="4">
          <v-card flat tile class="d-flex">
            <v-img
              :src="item.src"
              aspect-ratio="1"
              class="grey lighten-2"
            >
            </v-img>
          </v-card>
        </v-col>

        <v-col align="center">
          <div><div><br></div><div class="centered text-h3">
            ภาพกิจกรรมปีที่ผ่านมา<br>
          </div>
            <div class="text-h5">
              (ประมวลภาพ PreMWIT2020)<br>
            </div>
            <v-btn
              color="red"
              large
              outlined
              rounded
              x-large
              elevation="6"
              @click="$router.push({ name: 'Pic2020' })"
            >
              <div>
                <span class="centered text-h5 bring" style="font-weight: 350" color="red">
                  Pre MWIT 2021
                </span>
              </div>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'LY',

  data () {
    return {
      itemsss: [
        {
          id: 1,
          src: require('../assets/PT2020/1.webp')
        },
        {
          id: 2,
          src: require('../assets/PT2020/2.webp')
        },
        {
          id: 3,
          src: require('../assets/PT2020/3.webp')
        },
        {
          id: 4,
          src: require('../assets/PT2020/4.webp')
        },
        {
          id: 5,
          src: require('../assets/PT2020/5.webp')
        },
        {
          id: 6,
          src: require('../assets/PT2020/6.webp')
        },
        {
          id: 7,
          src: require('../assets/PT2020/7.webp')
        },
        {
          id: 8,
          src: require('../assets/PT2020/8.webp')
        },
        {
          id: 9,
          src: require('../assets/PT2020/9.webp')
        },
        {
          id: 10,
          src: require('../assets/PT2020/10.webp')
        },
        {
          id: 11,
          src: require('../assets/PT2020/11.webp')
        },
        {
          id: 12,
          src: require('../assets/PT2020/12.webp')
        },
        {
          id: 13,
          src: require('../assets/PT2020/13.webp')
        },
        {
          id: 14,
          src: require('../assets/PT2020/14.webp')
        },
        {
          id: 15,
          src: require('../assets/PT2020/15.webp')
        },
        {
          id: 16,
          src: require('../assets/PT2020/16.webp')
        },
        {
          id: 17,
          src: require('../assets/PT2020/17.webp')
        },
        {
          id: 18,
          src: require('../assets/PT2020/18.webp')
        },
        {
          id: 19,
          src: require('../assets/PT2020/19.webp')
        },
        {
          id: 20,
          src: require('../assets/PT2020/20.webp')
        },
        {
          id: 21,
          src: require('../assets/PT2020/21.webp')
        },
        {
          id: 22,
          src: require('../assets/PT2020/22.webp')
        },
        {
          id: 23,
          src: require('../assets/PT2020/23.webp')
        },
        {
          id: 24,
          src: require('../assets/PT2020/24.webp')
        },
        {
          id: 25,
          src: require('../assets/PT2020/25.webp')
        },
        {
          id: 26,
          src: require('../assets/PT2020/26.webp')
        },
        {
          id: 27,
          src: require('../assets/PT2020/27.webp')
        },
        {
          id: 28,
          src: require('../assets/PT2020/28.webp')
        },
        {
          id: 29,
          src: require('../assets/PT2020/29.webp')
        },
        {
          id: 30,
          src: require('../assets/PT2020/30.webp')
        },
        {
          id: 31,
          src: require('../assets/PT2020/31.webp')
        },
        {
          id: 32,
          src: require('../assets/PT2020/32.webp')
        },
        {
          id: 33,
          src: require('../assets/PT2020/33.webp')
        },
        {
          id: 34,
          src: require('../assets/PT2020/34.webp')
        },
        {
          id: 35,
          src: require('../assets/PT2020/35.webp')
        },
        {
          id: 36,
          src: require('../assets/PT2020/36.webp')
        },
        {
          id: 37,
          src: require('../assets/PT2020/37.webp')
        },
        {
          id: 38,
          src: require('../assets/PT2020/38.webp')
        },
        {
          id: 39,
          src: require('../assets/PT2020/39.webp')
        },
        {
          id: 40,
          src: require('../assets/PT2020/40.webp')
        },
        {
          id: 41,
          src: require('../assets/PT2020/41.webp')
        },
        {
          id: 42,
          src: require('../assets/PT2020/42.webp')
        },
        {
          id: 43,
          src: require('../assets/PT2020/43.webp')
        },
        {
          id: 44,
          src: require('../assets/PT2020/44.webp')
        },
        {
          id: 45,
          src: require('../assets/PT2020/45.webp')
        },
        {
          id: 46,
          src: require('../assets/PT2020/46.webp')
        },
        {
          id: 47,
          src: require('../assets/PT2020/47.webp')
        },
        {
          id: 48,
          src: require('../assets/PT2020/48.webp')
        }
      ]
    }
  }
}
</script>
